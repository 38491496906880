import { Suggestion } from '@/types'
import useActiveTemplate from './useActiveTemplate'
import useDefaultRecommendStyles from './useDefaultRecommendStyles'
import { useMemo } from 'react'

const useSuggestions = () => {
  const { data: activeTemplate, id: activeTemplateId } = useActiveTemplate()

  const { data: recommendStyles } = useDefaultRecommendStyles()
  const suggestions: Suggestion[] = useMemo(() => {
    if (activeTemplateId) {
      return (
        activeTemplate?.styles?.map((style) => {
          return {
            key: style.id,
            label: style.label,
            thumbnail: style.examples?.[0]?.thumbnail_url,
            prompt_append: '',
          }
        }) ?? []
      )
    }

    return recommendStyles ?? []
  }, [activeTemplate, recommendStyles, activeTemplateId])

  return suggestions
}

export default useSuggestions
