import { PoNVoid } from '@/types'
import { cls, preventDefaultAndStopPropagation, round2 } from '@/utils'
import { CSSProperties, PropsWithChildren, useCallback, useRef, useState } from 'react'

export interface TimelineThumbProps {
  className?: string
  onMove?: (percent: number) => PoNVoid
  style?: CSSProperties
}

export default function TimelineThumb({ className, children, onMove, style }: PropsWithChildren<TimelineThumbProps>) {
  const ref = useRef<HTMLDivElement | null>(null)

  const handleMouseMove = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      const { clientX } = e
      const thumb = ref.current
      const parent = thumb?.parentElement
      if (!thumb || !parent) {
        return
      }

      const thumbWidth = thumb.clientWidth

      const prevSibling = thumb.previousElementSibling as HTMLDivElement | null
      const nextSibling = thumb.nextElementSibling as HTMLDivElement | null

      const prevSiblingRect = prevSibling?.getBoundingClientRect()
      const nextSiblingRect = nextSibling?.getBoundingClientRect()

      const parentRect = parent.getBoundingClientRect()
      const minClientX = Math.max(0, prevSiblingRect ? prevSiblingRect.right + thumbWidth / 2 : 0)

      const maxClientX = Math.min(
        parent.clientWidth + parentRect.left,
        nextSiblingRect ? nextSiblingRect.left - thumbWidth / 2 : Number.MAX_SAFE_INTEGER,
      )

      const finalClientX = Math.min(maxClientX, Math.max(minClientX, clientX))

      const { left, width } = parent.getBoundingClientRect()
      const percent = round2(Math.min(1, Math.max(0, (finalClientX - left) / width)) * 100)
      thumb.style.left = `${percent}%`
      onMove?.(percent)
    },
    [onMove],
  )

  const handleDragEnd = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      preventDefaultAndStopPropagation(e as any)
      document.removeEventListener('mousemove', handleMouseMove as any)
      // setDragging(false)
    },
    [handleMouseMove],
  )

  const handleDragStart = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      preventDefaultAndStopPropagation(e as any)
      document.addEventListener('mousemove', handleMouseMove as any)
      document.addEventListener('mouseup', (e) => {
        document.removeEventListener('mousemove', handleMouseMove as any)
        handleDragEnd(e as any)
      })
      // setDragging(true)
    },
    [handleMouseMove, handleDragEnd],
  )

  return (
    <div
      ref={ref}
      className={cls('absolute size-8 shrink-0 rounded-lg p-1 cursor-move box-content', className)}
      aria-label='thumb'
      style={style}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDrop={handleDragEnd}
    >
      {children}
    </div>
  )
}
