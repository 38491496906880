import { useCachedService } from '@banyudu/use-service'
import { getCreditPricingTable } from '@/service/pay.service'
import useAuth0Auth from './useAuth0Auth'
import { useMemo } from 'react'

const skipableGetCreditPricingTable = async (params?: { skip?: boolean }) => {
  if (params?.skip) return null
  const res = await getCreditPricingTable()
  return res
}

// const _useCreditPricingTable = useService(skipableGetCreditPricingTable)
// const useCreditPricingTable = () => {
//   const { isLogin } = useAuth0Auth()

//   const params = useMemo(() => {
//     if (!isLogin) return { skip: true }
//     return {}
//   }, [isLogin])

//   return _useCreditPricingTable(params)
// }

const _useCachedCreditPricingTable = useCachedService(
  skipableGetCreditPricingTable,
)

export const useCachedCreditPricingTable = () => {
  const { isLogin } = useAuth0Auth()

  const params = useMemo(() => {
    if (!isLogin) return { skip: true }
    return {}
  }, [isLogin])

  return _useCachedCreditPricingTable(params)
}

// export default useCreditPricingTable
