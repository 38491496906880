import useCreationSettings from './useCreationSettings'

const useDefaultRecommendStyles = () => {
  const { data: creationSettings, ...others } = useCreationSettings()
  return {
    data: creationSettings?.styles ?? undefined,
    ...others,
  }
}
export default useDefaultRecommendStyles
