import { cls } from '@/utils'

export interface BadgeProps {
  className?: string
  children: string
}

export default function Badge({ className, children }: BadgeProps) {
  return (
    <div
      className={cls(
        'px-1 py-0.5 border border-surface-primary rounded-[5px] flex items-center justify-center',
        className,
      )}
    >
      <div className='text-body-sm font-bold leading-3 text-center h-3 max-h-3'>{children}</div>
    </div>
  )
}
