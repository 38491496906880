import Checkbox from '@/components/checkbox'
import { cls } from '@/utils'
import { Suggestion } from '@/types'
import Image from '@/components/image'
import useSuggestions from '@/hooks/useSuggestions'

interface SuggestionProps {
  className?: string
  value?: string
  onChange?: (value: string) => void
}

export default function Suggestions({ className, value, onChange }: SuggestionProps) {
  const suggestions: Suggestion[] = useSuggestions()

  if (!suggestions?.length) {
    return null
  }

  return (
    <div className={cls('grid grid-cols-2 gap-2', className)} aria-label='suggestions'>
      {suggestions.map((style: Suggestion) => {
        const checked = style.key === value
        return (
          <div
            key={style.key}
            aria-label={`style-${style.key}`}
            className={cls(
              'relative group box-border border border-border rounded-md flex flex-col items-center gap-1 p-2 pb-1 hover:border-border-hover hover:bg-surface-hover cursor-pointer select-none',
              checked ? 'border-border-active hover:border-border-active border-2 p-[7px] pb-[3px]' : '',
            )}
            onClick={() => onChange?.(style.key === value ? '' : style.key)}
          >
            <div className='w-[114px] h-[52px] overflow-hidden'>
              <Image
                className='rounded-md object-cover max-w-full max-h-full'
                src={style.thumbnail}
                alt='thumbnail'
                draggable={false}
                width={114}
                height={52}
              />
            </div>
            <span className={cls('text-body-md tracking-15 truncate w-full')} title={style.label}>
              {style.label}
            </span>
            {checked ? (
              <div className='absolute right-1 inset-y-1 flex flex-col items-center gap-0.5 bg-surface group-hover:bg-surface-hover p-[3px] rounded-md aspect-square size-6'>
                <Checkbox checked className='size-[18px]' />
              </div>
            ) : null}
          </div>
        )
      })}
    </div>
  )
}
