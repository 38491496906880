import { useState } from 'react'
import { cls } from '@/utils'
import { CreationModeList } from './list'
import { ButtonCreationMode, ButtonCreationModeProps } from './button'
import { Modal } from '@/components/modal'

interface CreationModeDialogProps extends ButtonCreationModeProps {}

export default function CreationModeDialog({ ...props }: CreationModeDialogProps) {
  const [open, setOpen] = useState(false)
  return (
    <Modal
      open={open}
      trigger={<ButtonCreationMode {...props} />}
      className='w-[332px] xs:w-[438px] md:w-[662px] h-auto max-h-[80vh] md:h-[780px] md:max-h-[calc(min(80vh,780px))] border border-border border-solid p-2 px-4 md:p-6 rounded-md'
      closeClassName='absolute top-4 right-4 md:top-4 md:right-4 border-0 border-none'
      onOpenChange={setOpen}
    >
      <CreationModeList
        inDialog
        className={cls('h-full overflow-y-hidden')}
        listClassName='min-h-0 overflow-y-auto no-scrollbar px-0 md:py-0 w-full'
        onSelectMode={() => setOpen(false)}
      />
    </Modal>
  )
}
