import { CreationInputControlsProps } from './common'
import { cls } from '@/utils'
// import Suggestion from './suggestion'

export default function T2vControls({
  expanded,
  textarea,
}: CreationInputControlsProps) {
  return (
    <fieldset
      className={cls(
        'self-start flex md:relative p-3 pt-0 bg-surface w-full shrink-0',
        expanded ? '' : 'hidden',
      )}
      aria-label='creation-input-fieldset'
    >
      <div className='flex w-full flex-col'>
        {/* {showSuggestions ? (
        <Suggestion
          addPrompt={addPrompt}
          visible={
            expanded &&
            ![
              CreationModeEnum.Animate,
              CreationModeEnum.AnimateHD,
            ].includes(mode) &&
            (mode !== CreationModeEnum.Repaint || Boolean(fileId))
          }
        />
      ) : null} */}
        {textarea}
      </div>
    </fieldset>
  )
}
