/**
 * @fileoverview GlobalContainer Component
 * @abstract used to create a container with global position
 */

import { sidebarWidthStyle } from '@/constants'
import useBroadcasts from '@/hooks/useBroadcasts'
import { cls } from '@/utils'
import { ReactElement } from 'react'

export interface GlobalContainerProps {
  children: ReactElement
  className?: string
  fixed?: boolean
}

export default function GlobalContainer({ children, className, fixed }: GlobalContainerProps) {
  const { broadcasts } = useBroadcasts()
  const hasBroadcasts = broadcasts?.length > 0

  return (
    <div className={cls('bg-transparent text-text z-10', fixed && 'fixed inset-x-0 top-0', className)}>
      {hasBroadcasts && <div className='h-12' />}
      <div className='flex max-w-[1728px] mx-auto w-full text-text'>
        {fixed && <div className={cls('invisible', sidebarWidthStyle)} />}
        <div className={cls('flex-1 bg-surface pt-5 pb-2 px-4 md:px-14 h-25 flex items-center')}>{children}</div>
      </div>
    </div>
  )
}
