import { CreationInputControlsProps } from './common'
import { cls } from '@/utils'
import GSUpload from '@/components/gs-upload'
// import Suggestion from './suggestion'
import { ButtonVaryRegion } from '../button-vary-region'
import SamPrompt from '../sam-prompt'
import { SamWrapper } from '../sam-wrapper'
import { useCachedSwitches } from '@/hooks/useSwitches'
import useModelVersion from '@/hooks/useModelVersion'
import { ModelVersion } from '@/types'

export default function V2vControls({
  expanded,
  setFile,
  uploading,
  setUploading,
  fileId,
  file,
  creation,
  textarea,
  onSamChange,
  onDeleteFile,
}: CreationInputControlsProps) {
  const { data: switches } = useCachedSwitches()
  const { version } = useModelVersion()
  const showSAM = !!switches?.sam && version !== ModelVersion.TWO

  return (
    <fieldset
      className={cls(
        'self-start flex md:relative p-3 pt-0 bg-surface w-full shrink-0',
        'flex flex-col justify-center gap-2 min-h-0 md:min-h-[auto]',
        expanded ? '' : 'hidden',
      )}
      aria-label='creation-input-fieldset'
    >
      <div className='flex flex-col items-center gap-3'>
        <GSUpload
          file={file}
          fileType='video'
          className={cls(
            'bg-surface hover:bg-surface-hover rounded-lg border border-solid border-border hover:border-border-hover',
            'md:w-[300px] md:h-[180px] md:mx-auto',
            fileId ? 'hidden' : '',
          )}
          uploading={uploading}
          setUploading={setUploading}
          onChange={setFile}
        />
      </div>
      {fileId ? (
        <SamWrapper
          key={fileId}
          className=''
          fileId={fileId}
          thumbnailUrl={file?.thumbnailUrl ?? creation?.thumbnail_url ?? ''}
          onChange={onSamChange}
          onDelete={onDeleteFile}
        />
      ) : (
        <div className={cls('flex relative justify-center', showSAM ? '' : 'invisible h-4')}>
          <ButtonVaryRegion disabled className={cls('')} />
          <SamPrompt />
        </div>
      )}
      <div className='flex w-full flex-col'>{textarea}</div>
    </fieldset>
  )
}
