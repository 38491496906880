import { cls } from '@/utils'
import ChevronRight from '@haiper/icons-svg/icons/outline/chevron-right-small.svg'
import TimelineThumb from './thumb'
import { useCallback, useEffect, useRef } from 'react'
import { PoNVoid } from '@/types'
import { every, isNil } from 'lodash-es'

export interface AfcTimelineItem {
  visible: boolean
  className: string
  thumbnail?: string
  percent?: number | null
}

export interface AfcTimelineProps {
  className?: string
  duration: number | null
  items: AfcTimelineItem[]
  onItemsChange?: (items: AfcTimelineItem[]) => PoNVoid
}

export default function AfcTimeline({ className, items, duration, onItemsChange }: AfcTimelineProps) {
  const thumbWidth = 40

  const thumbsRef = useRef<HTMLDivElement | null>(null)
  const handleThumbMove = useCallback(
    (index: number, percent: number) => {
      const newItems = [...items]
      newItems[index].percent = percent
      onItemsChange?.(newItems)
    },
    [onItemsChange, items],
  )

  useEffect(() => {
    // check unplaced items
    setTimeout(() => {
      const container = thumbsRef.current
      if (!container) {
        return
      }
      if (every(items, (item) => !isNil(item.percent) || !item.visible)) {
        return
      }
      const containerRect = container.getBoundingClientRect()
      const containerWidth = containerRect.width
      const delta = (thumbWidth * 100) / containerWidth

      const newItems = [...items]
      newItems.forEach((item) => {
        if (!item.visible) {
          item.percent = null
        }
      })

      const visibleItems = newItems.filter((item) => item.visible)
      const firstItem = newItems[0]
      const middleItem = newItems.length === 3 ? newItems[1] : null
      const lastItem = newItems[newItems.length - 1]

      if (firstItem && isNil(firstItem?.percent) && firstItem.visible) {
        firstItem.percent = 0
      }
      if (lastItem && isNil(lastItem?.percent) && lastItem.visible) {
        lastItem.percent = 100
      }
      if (middleItem?.visible && isNil(middleItem.percent)) {
        middleItem.percent = ((firstItem?.percent ?? 0) + (lastItem?.percent ?? 100)) / 2
      }

      // resolve the conflict
      // form left to right
      for (let i = 1; i < visibleItems.length; i++) {
        const prevItem = visibleItems[i - 1]
        const currentItem = visibleItems[i]
        if (currentItem.percent! - prevItem.percent! < delta) {
          currentItem.percent = Math.min(100, prevItem.percent! + delta)
        }
      }

      // from right to left
      for (let i = visibleItems.length - 2; i >= 0; i--) {
        const nextItem = visibleItems[i + 1]
        const currentItem = visibleItems[i]
        if (nextItem.percent! - currentItem.percent! < delta) {
          currentItem.percent = Math.max(0, nextItem.percent! - delta)
        }
      }

      onItemsChange?.(newItems)
    }, 0)
  }, [items, onItemsChange])

  if (!duration) {
    return null
  }
  return (
    <div className={cls('flex flex-col w-full gap-1 text-text-subdued', className)} aria-label='afc-timeline'>
      <div className='w-full flex justify-between items-center text-body-sm' aria-label='time indicator'>
        <span>0s</span>
        <span>{duration}s</span>
      </div>
      <div className='relative w-full bg-surface-subdued h-10 p-1 rounded-lg' aria-label='bar'>
        <div
          className='absolute z-0 flex gap-1 items-center w-full overflow-hidden h-8 max-w-full'
          aria-label='background'
        >
          {new Array(50).fill(0).map((item, index) => {
            return (
              <div key={index} className='h-full flex items-center'>
                <ChevronRight className='size-5 text-icon-disable shrink-0' />
              </div>
            )
          })}
        </div>
        <div className='absolute z-10 inset-0'>
          <div ref={thumbsRef} className='absolute inset-x-5 inset-y-0 flex scroll-smooth' aria-label='thumbs'>
            {items.map((item, index) => {
              if (!item.visible) {
                return null
              }
              return (
                <TimelineThumb
                  key={index}
                  className={cls(
                    'shrink-0 rounded-lg p-1 -translate-x-5 border border-b-2',
                    index === 0 && 'bg-surface-active border-border-active',
                    index === 1 && 'bg-surface-caution border-border-caution',
                    index === 2 && 'bg-surface-success border-border-success',
                  )}
                  style={{ left: `${item.percent}%` }}
                  onMove={(percent: number) => handleThumbMove(index, percent)}
                >
                  <img
                    width={32}
                    height={32}
                    src={item.thumbnail}
                    alt=''
                    className='size-8 shrink-0 object-cover rounded-md'
                  />
                </TimelineThumb>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}
