import { cls } from '@/utils'
import { CSSProperties, useCallback, useMemo, useState } from 'react'
import IconZoomIn from '@haiper/icons-svg/icons/outline/zoom-in.svg'
import IconZoomOut from '@haiper/icons-svg/icons/outline/zoom-out.svg'
import IconArrowLeft from '@haiper/icons-svg/icons/outline/arrow-left.svg'
import IconArrowRight from '@haiper/icons-svg/icons/outline/arrow-right.svg'
import IconArrowTop from '@haiper/icons-svg/icons/outline/arrow-top.svg'
import IconArrowBottom from '@haiper/icons-svg/icons/outline/arrow-bottom.svg'
import IconCameraExposure from '@haiper/icons-svg/icons/outline/camera-exposure-square.svg'
import Button from '@/components/button'
import { CameraMovement } from '@/types'
import { useBreakpoint } from '@/hooks/useBreakPoint'

export interface CameraMovementProps {
  className?: string
  value?: CameraMovement
  onChange?: (value: CameraMovement) => void
}

const previewClassNameMap = {
  none: '',
  zoom_in: 'inset-x-[14px] inset-y-2',
  zoom_out: 'inset-x-[-14px] inset-y-[-8px]',
  pan_left: '',
  pan_right: '',
  tilt_up: '',
  tilt_down: '',
}

const previewStyleMap: Record<CameraMovement, CSSProperties> = {
  none: {},
  zoom_in: {},
  zoom_out: {},
  pan_left: {
    transform: 'perspective(10px) rotateY(0.5deg) translateX(-8px)',
  },
  pan_right: {
    transform: 'perspective(10px) rotateY(-0.5deg) translateX(8px)',
  },
  tilt_up: {
    transform: 'perspective(10px) rotateX(-1deg) translateY(-6px)',
  },
  tilt_down: {
    transform: 'perspective(10px) rotateX(1deg) translateY(6px)',
  },
}

export default function CameraMovementSetting({ value, onChange, className }: CameraMovementProps) {
  const { isBelowMd } = useBreakpoint('md')
  const groups = useMemo(() => {
    return [
      {
        label: 'Zoom',
        buttons: [
          {
            key: 'zoom_in',
            icon: IconZoomIn,
          },
          {
            key: 'zoom_out',
            icon: IconZoomOut,
          },
        ],
      },
      {
        label: 'Pan',
        buttons: [
          {
            key: 'pan_left',
            icon: IconArrowLeft,
          },
          {
            key: 'pan_right',
            icon: IconArrowRight,
          },
        ],
      },
      {
        label: 'Tilt',
        buttons: [
          {
            key: 'tilt_up',
            icon: IconArrowTop,
          },
          {
            key: 'tilt_down',
            icon: IconArrowBottom,
          },
        ],
      },
    ]
  }, [])

  const [hoveringItem, setHoveringItem] = useState<CameraMovement | null>(null)
  const previewItem = (isBelowMd ? null : hoveringItem) ?? value

  const handleMouseEnter = useCallback((item: CameraMovement) => {
    setHoveringItem(item)
  }, [])

  const handleMouseLeave = useCallback((item: CameraMovement) => {
    setHoveringItem((old) => (old === item ? null : old))
  }, [])

  return (
    <div className={cls('flex flex-col gap-2 p-4 bg-surface', className)}>
      <span className='text-body-md mb-2'>Camera Movement</span>
      <div className='w-full aspect-video px-11 py-6 bg-surface-subdued rounded-md' aria-label='preview'>
        <div className='relative aspect-video bg-surface rounded-sm transition-all transform-gpu duration-500 flex justify-center items-center'>
          <IconCameraExposure className='size-5 text-icon-disable' />
          <div
            className={cls(
              // bg-surface-primary/50
              'absolute inset-0 bg-[rgba(123,99,255,0.20)] dark:bg-[rgba(148,129,255,0.20)] border border-surface-primary flex items-center justify-center rounded-sm transition-all transform-gpu duration-500 perspective-[10px]',
              previewClassNameMap[previewItem ?? 'none'],
            )}
            style={previewStyleMap[previewItem ?? 'none']}
            aria-label='mask'
          >
            <IconCameraExposure className='size-5 text-icon-interactive' />
          </div>
        </div>
      </div>
      <div className='flex flex-col w-full gap-2' aria-label='buttons'>
        {groups.map((group) => {
          return (
            <div key={group.label} className='flex gap-6 justify-between items-center'>
              <span className='text-body-lg tracking-32 w-[45px]'>{group.label}</span>
              <div className='flex gap-2 items-center'>
                {group.buttons.map((button) => {
                  const isActive = button.key === value
                  return (
                    <Button
                      key={button.key}
                      variant='outline'
                      className={cls(
                        'flex items-center justify-center w-24 h-10 rounded-md px-3 py-2',
                        isActive && 'border-border-active border-2 text-icon-interactive hover:text-icon-interactive',
                      )}
                      aria-label={button.key}
                      onClick={() => onChange?.(isActive ? 'none' : (button.key as CameraMovement))}
                      onMouseEnter={() => handleMouseEnter(button.key as CameraMovement)}
                      onMouseLeave={() => handleMouseLeave(button.key as CameraMovement)}
                    >
                      <button.icon className='size-6 text-icon' />
                    </Button>
                  )
                })}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
