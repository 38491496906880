import { useCallback, useEffect, MutableRefObject } from 'react'

function assertIsNode(e: EventTarget | null): asserts e is Node {
  if (!e || !('nodeType' in e)) {
    throw new Error('Node expected')
  }
}

function assertIsElement(e: EventTarget | null): asserts e is Element {
  if (!e || !(e instanceof Element)) {
    throw new Error('Element expected')
  }
}

interface ClickOutsideProps {
  excludeSelector?: string[]
  boundElements?: HTMLElement[]
  ref: MutableRefObject<HTMLElement | null>
  onClickOutside: (e: MouseEvent) => void
}

const eventTypes = ['click', 'touchstart'] as const

export function useClickOutside({
  boundElements,
  ref,
  excludeSelector,
  onClickOutside,
}: ClickOutsideProps) {
  const handleEvent = useCallback(
    (e: MouseEvent) => {
      const els = [
        ...(ref.current ? [ref.current] : []),
        ...(boundElements ?? []),
      ]
      if (!els.length) return
      const target = e.target
      assertIsNode(target)
      const isClickOutside = els.every((el) => {
        if (el.contains(target)) {
          return false
        }
        if (excludeSelector && excludeSelector.length > 0) {
          assertIsElement(target)
          if (excludeSelector.some((selector) => target.closest(selector))) {
            return false
          }
        }
        return true
      })
      if (isClickOutside) {
        onClickOutside(e)
      }
    },
    [boundElements, ref, excludeSelector, onClickOutside],
  )

  useEffect(() => {
    eventTypes.forEach((eventType) => {
      document.addEventListener(eventType, handleEvent as any, true)
    })
    return () => {
      eventTypes.forEach((eventType) => {
        document.removeEventListener(eventType, handleEvent as any, true)
      })
    }
  }, [handleEvent])
}
