import { CreationInputControlsProps } from './common'
import { cls } from '@/utils'
import GSUpload from '@/components/gs-upload'
import IconDiamond from '@haiper/icons-svg/icons/outline/diamond.svg'
import IconHD from '@haiper/icons-svg/icons/outline/hd-2.svg'

export default function UpscaleControls({
  expanded,
  setFile,
  uploading,
  setUploading,
  file,
}: CreationInputControlsProps) {
  return (
    <fieldset
      className={cls(
        'self-start flex md:relative p-3 pt-0 bg-surface w-full shrink-0',
        expanded ? '' : 'hidden',
      )}
      aria-label='creation-input-fieldset'
    >
      <div className='w-full flex flex-col items-center gap-4 md:gap-3'>
        <div className='px-3 py-2 rounded-md bg-surface-subdued flex items-center justify-center size-full'>
          <div className='flex flex-col gap-1' aria-label='upscale upload note'>
            <div className='flex gap-2 items-center'>
              <IconHD className='size-4 text-icon' />
              <span className='text-text-subdued text-body-sm'>
                1080p with enhanced fidelity
              </span>
            </div>
            <div className='flex gap-2 items-center'>
              <IconDiamond className='size-4 text-icon' />
              <span className='text-text-subdued text-body-sm'>
                10 credits per second
              </span>
            </div>
          </div>
        </div>
        <div className='w-full flex items-center justify-center'>
          <GSUpload
            file={file}
            fileType='video'
            maxDuration={12}
            uploading={uploading}
            setUploading={setUploading}
            onChange={setFile}
          />
        </div>
      </div>
    </fieldset>
  )
}
