'use client'

import { CreationMode } from '@/types'
import useCreationModes from './useCreationModes'
import { useAtom, useAtomValue } from 'jotai'
import { activeTemplateIdAtom, creationInputAtom } from '@/atoms'
import { useEffect, useMemo } from 'react'

export default function useCurrentCreationMode(): {
  data: CreationMode | null
  loading: boolean
} {
  const { data: creationModeOptions } = useCreationModes()
  const [creationInput, setCreationInput] = useAtom(creationInputAtom)
  const activeTemplateId = useAtomValue(activeTemplateIdAtom)
  const result = useMemo(() => {
    if (activeTemplateId) {
      return null
    }
    return creationModeOptions.find((option) => option.mode === creationInput?.mode)
  }, [creationInput, creationModeOptions, activeTemplateId])

  useEffect(() => {
    if (!result && creationModeOptions.length && !activeTemplateId) {
      setCreationInput((prev) => {
        return {
          ...prev,
          mode: creationModeOptions[0].mode,
        }
      })
    }
  }, [result, creationModeOptions, setCreationInput, activeTemplateId])

  return {
    data: result ?? null,
    loading: false,
  }
}
