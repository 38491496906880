import { getProfile } from '@/service/auth.service'
import { Profile } from '@/types'
import useService, { useCachedService } from '@banyudu/use-service'
import { useMemo } from 'react'
import useAuth0Auth from './useAuth0Auth'

let promise: Promise<Profile> | null = null

const skipableGetProfile = async (params?: {
  skip?: boolean
}): Promise<Profile | null> => {
  if (params?.skip) return null
  if (!promise) {
    promise = getProfile()
  }
  return promise
}

const useMyProfile = useService(getProfile)

const _useCachedMyProfile = useCachedService(
  skipableGetProfile,
  (params) => !params?.skip,
)

export const useCachedMyProfile = () => {
  const { isLogin } = useAuth0Auth()

  const params = useMemo(() => {
    if (!isLogin) return { skip: true }
    return {}
  }, [isLogin])

  const result = _useCachedMyProfile(params)
  return result
}

export default useMyProfile
