import { cls } from '@/utils'
import NextLink, { LinkProps } from 'next/link'
import { ComponentPropsWithoutRef, forwardRef } from 'react'

const Link = forwardRef<HTMLAnchorElement, LinkProps & Omit<ComponentPropsWithoutRef<'a'>, 'href'>>(
  ({ className, ...props }, ref) => {
    return (
      <NextLink
        ref={ref}
        prefetch
        className={cls('[&_span]:hover:opacity-80', className, 'hover:no-underline')}
        {...props}
      />
    )
  },
)

Link.displayName = 'Link'

export type { LinkProps }
export default Link
