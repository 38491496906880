import Button from '@/components/button'
import { cls } from '@/utils'
import ChevronTop from '@haiper/icons-svg/icons/outline/chevron-large-top.svg'

export interface CreationInputMaskProps {
  className?: string
  onClick?: () => void
}

export default function CreationInputMask({ className, onClick }: CreationInputMaskProps) {
  return (
    <div
      className={cls(
        'group absolute inset-0 backdrop-blur-[1px] flex items-center justify-center z-20 p-2.5 cursor-pointer bg-white/33 dark:bg-[rgba(42,40,51,0.33)] rounded-t-lg md:rounded-[24px]',
        className,
      )}
      onClick={onClick}
    >
      <Button
        variant='transparent'
        className={cls('hover:bg-surface-subdued group-hover:bg-surface-subdued px-3 py-2 rounded-full h-max w-max')}
      >
        <div className='flex items-center justify-center'>
          <div className='flex flex-col items-center'>
            <div className='h-2 relative overflow-hidden w-full flex justify-center'>
              <ChevronTop className='absolute top-0 w-max text-icon translate-y-[-8px]' />
            </div>
            <span className='text-text px-1'>Click to create</span>
          </div>
        </div>
      </Button>
    </div>
  )
}
