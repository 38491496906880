import IconUpdate from '@/public/assets/update.svg'
import { cls } from '@/utils'

export interface BadgeUpdateProps {
  className?: string
}

export default function BadgeUpdate({ className }: BadgeUpdateProps) {
  return (
    <div
      className={cls(
        'px-1 py-0.5 border border-border rounded-[5px]',
        className,
      )}
    >
      <IconUpdate className='text-text-interactive' />
    </div>
  )
}
