import { Plan } from '@/types'
import { useMemo } from 'react'
import { useCachedPricingTable } from './usePricingTable'
import { DEFAULT_CURRENCY, MEMBERSHIP_FEATURES, MEMBERSHIP_TIER_IDS } from '@/constants'
import { useCachedSubscription } from './useSubscription'

const usePlans = (): {
  data: Plan[]
  loading: boolean
} => {
  const { data: pricingTable, isValidating: pricingTableLoading } = useCachedPricingTable()
  const { data: subscription, loading: subscriptionLoading } = useCachedSubscription()

  const loading = pricingTableLoading || subscriptionLoading

  const currency = useMemo(() => {
    return subscription?.subscriptions?.filter((e) => e.status === 'active')?.[0]?.currency ?? DEFAULT_CURRENCY
  }, [subscription])

  const plans: Plan[] = useMemo(() => {
    const enterprisePlan: Plan = {
      name: 'Enterprise APIs',
      tier_id: MEMBERSHIP_TIER_IDS.ENTERPRISE,
      tier_name: 'Enterprise APIs',
      max_queue_size: Number.MAX_SAFE_INTEGER,
      max_concurrent_tasks: Number.MAX_SAFE_INTEGER,
      max_generation_tasks: Number.MAX_SAFE_INTEGER,
      allow_private_generation: true,
      allow_watermark_free_download: true,
      monthly_credit_allocation: 0,
      features: MEMBERSHIP_FEATURES[MEMBERSHIP_TIER_IDS.ENTERPRISE] ?? [],
      is_free: false,
    }

    const unlimitedPlan: Plan = {
      name: 'Unlimited',
      tier_id: MEMBERSHIP_TIER_IDS.UNLIMITED,
      tier_name: 'Unlimited',
      max_queue_size: Number.MAX_SAFE_INTEGER,
      max_concurrent_tasks: Number.MAX_SAFE_INTEGER,
      max_generation_tasks: Number.MAX_SAFE_INTEGER,
      allow_private_generation: true,
      allow_watermark_free_download: true,
      monthly_credit_allocation: 0,
      features: MEMBERSHIP_FEATURES[MEMBERSHIP_TIER_IDS.UNLIMITED] ?? [],
      is_free: false,
    }

    if (!Array.isArray(pricingTable)) {
      return []
    }

    const result =
      pricingTable?.map((pricingTableItem) => {
        const monthlyPrice = pricingTableItem.prices?.find(
          (price) => price.recurring.interval === 'month' && price.active && (price.currency === currency || !currency),
        )
        const yearlyPrice = pricingTableItem.prices?.find(
          (price) => price.recurring.interval === 'year' && price.active && (price.currency === currency || !currency),
        )
        return {
          ...pricingTableItem,
          name: pricingTableItem.tier_name,
          features: MEMBERSHIP_FEATURES[pricingTableItem.tier_id] ?? [],
          is_free: pricingTableItem.tier_id === MEMBERSHIP_TIER_IDS.FREE,
          prices: {
            monthly: monthlyPrice?.unit_amount ?? Number.NaN,
            monthly_id: monthlyPrice?.id ?? '',
            monthly_currency: monthlyPrice?.currency ?? '',
            yearly: yearlyPrice?.unit_amount ?? Number.NaN,
            yearly_id: yearlyPrice?.id ?? '',
            yearly_currency: yearlyPrice?.currency ?? '',
          },
          product_id: pricingTableItem.product?.id ?? '',
        }
      }) ?? []

    return [...result, unlimitedPlan, enterprisePlan]
  }, [pricingTable, currency])

  return {
    data: plans,
    loading,
  }
}

export default usePlans
