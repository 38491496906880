import { CreationInputControlsProps } from './common'
import { cls } from '@/utils'
import GSUpload from '@/components/gs-upload'
// import Suggestion from './suggestion'
import IconStopWatch from '@haiper/icons-svg/icons/outline/stopwatch.svg'
import IconDiamond from '@haiper/icons-svg/icons/outline/diamond.svg'
import IconPlus from '@haiper/icons-svg/icons/outline/plus-small.svg'

export default function ExtendControls({
  expanded,
  setFile,
  uploading,
  setUploading,
  file,
  textarea,
}: CreationInputControlsProps) {
  return (
    <fieldset
      className={cls('self-start flex flex-col p-3 pt-0 bg-surface w-full shrink-0', expanded ? '' : 'hidden')}
      aria-label='creation-input-fieldset'
    >
      <div className='flex flex-col items-center gap-3'>
        <div className='px-3 py-2 rounded-md bg-surface-subdued flex items-center justify-center h-full w-full'>
          <div className='flex flex-col gap-1' aria-label='extend upload note'>
            <div className='flex gap-2 items-center'>
              <IconStopWatch className='size-4 text-icon' />
              <span className='text-text-subdued text-body-sm'>Extend your video by 2 or 4 seconds</span>
            </div>
            <div className='flex gap-2 items-center'>
              <IconDiamond className='size-4 text-icon' />
              <span className='text-text-subdued text-body-sm'>5 credits per extended second</span>
            </div>
          </div>
        </div>
        <div className='w-full flex flex-col items-center gap-1'>
          <GSUpload
            file={file}
            fileType='video'
            minDuration={2}
            maxDuration={12}
            uploading={uploading}
            setUploading={setUploading}
            onChange={setFile}
          />
          <div className='flex items-center justify-center'>
            <IconPlus className='size-5 text-icon-subdued' />
          </div>
          <div className='flex w-full flex-col'>{textarea}</div>
        </div>
      </div>
    </fieldset>
  )
}
