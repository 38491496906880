'use client'

import { getLocalStorage, setLocalStorage } from '@/utils'
import { atom, useAtom } from 'jotai'
import { isPlainObject } from 'lodash-es'
import { useCallback, useEffect, useMemo } from 'react'

const tutorialStatesAtom = atom<Record<string, boolean>>(
  (() => {
    const tutorialStates = getLocalStorage<Record<string, boolean>>('tutorialStates')
    if (tutorialStates && isPlainObject(tutorialStates)) return tutorialStates
    return {}
  })(),
)

export default function useTutorialStates(feature: string) {
  const [tutorialStates, setTutorialStates] = useAtom(tutorialStatesAtom)

  const isReaded = useMemo(() => {
    return !!tutorialStates[feature] || !feature
  }, [tutorialStates, feature])

  const read = useCallback(() => {
    if (!feature) {
      return
    }
    setTutorialStates((prev) => ({ ...prev, [feature]: true }))
  }, [setTutorialStates, feature])

  useEffect(() => {
    setLocalStorage('tutorialStates', tutorialStates)
  }, [tutorialStates])

  return {
    isReaded,
    read,
  }
}
