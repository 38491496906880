import { useMemo } from 'react'
import useCredit from './useCredit'
import { CREATE_MODE_ALIAS, DEFAULT_SKUS, TEMPLATE_SKUS } from '@/constants'
import { useAtomValue } from 'jotai'
import { activeTemplateIdAtom, creationInputAtom } from '@/atoms'
import useModelVersion from './useModelVersion'
import { ModelVersion } from '@/types'

const useDurationOptions = () => {
  const { spendProductsList } = useCredit()
  const creationInput = useAtomValue(creationInputAtom)
  const activeTemplateId = useAtomValue(activeTemplateIdAtom)
  const { version } = useModelVersion()

  const durationOptions = useMemo(() => {
    if (activeTemplateId) {
      return TEMPLATE_SKUS
    }

    const spendSpu = spendProductsList.find(
      (item) => item.spu === (CREATE_MODE_ALIAS as any)[version][creationInput?.mode || 'CreateHD'],
    )

    let result = spendSpu?.skus || DEFAULT_SKUS

    // TODO: remove this line after all durations are available
    if (version === ModelVersion.TWO) {
      result = result.filter((item) => [4, 6].includes(item.second ?? 0))
    }

    return result
  }, [spendProductsList, creationInput, activeTemplateId, version])

  return durationOptions
}

export default useDurationOptions
