import IconNew from '@/public/assets/new.svg'
import { cls } from '@/utils'

export interface BadgeNewProps {
  className?: string
}

export default function BadgeNew({ className }: BadgeNewProps) {
  return (
    <div
      className={cls(
        'px-1 py-0.5 border border-border rounded-[5px]',
        className,
      )}
    >
      <IconNew className='text-text-interactive' />
    </div>
  )
}
