import { Popover as BasePopover, PopoverClose, PopoverContent, PopoverTrigger } from '@/components/ui/popover'
import { useBreakpoint } from '@/hooks/useBreakPoint'
import { cls } from '@/utils'
import { PopoverContentProps, PopoverPortal } from '@radix-ui/react-popover'
import {
  ComponentPropsWithoutRef,
  forwardRef,
  PropsWithChildren,
  ReactElement,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'

export type PopoverProps = PropsWithChildren<
  {
    trigger: ReactElement
    className?: string
    popOnHover?: boolean
    align?: PopoverContentProps['align']
    side?: PopoverContentProps['side']
    onMouseEnter?: PopoverContentProps['onMouseEnter']
    onMouseLeave?: PopoverContentProps['onMouseLeave']
  } & ComponentPropsWithoutRef<typeof BasePopover>
>

export interface PopoverRef {
  close: () => void
}

const Popover = forwardRef(
  (
    {
      trigger,
      children,
      className,
      popOnHover,
      align = 'center',
      side,
      open,
      onMouseEnter,
      onMouseLeave,
      onOpenChange,
      ...props
    }: PopoverProps,
    ref,
  ) => {
    const [triggerHovering, setTriggerHovering] = useState(false)
    const [contentHovering, setContentHovering] = useState(false)

    const triggerHoveringTimer = useRef<any>(null)
    const contentHoveringTimer = useRef<any>(null)

    const hiddenCloseHandleRef = useRef<HTMLDivElement>(null)

    const close = useCallback(() => {
      hiddenCloseHandleRef.current?.click()
    }, [])

    useImperativeHandle(
      ref,
      () => ({
        close,
      }),
      [close],
    )

    const handleTrigerMouseEnter = useCallback(
      (e: any) => {
        if (triggerHoveringTimer.current) {
          clearTimeout(triggerHoveringTimer.current)
        }
        triggerHoveringTimer.current = setTimeout(() => {
          setTriggerHovering(true)
        }, 200)

        onMouseEnter?.(e)
      },
      [onMouseEnter],
    )

    const handleTriggerMouseLeave = useCallback(
      (e: any) => {
        if (triggerHoveringTimer.current) {
          clearTimeout(triggerHoveringTimer.current)
        }
        triggerHoveringTimer.current = setTimeout(() => {
          setTriggerHovering(false)
        }, 200)

        onMouseLeave?.(e)
      },
      [onMouseLeave],
    )

    const handleContentMouseEnter = useCallback(() => {
      if (contentHoveringTimer.current) {
        clearTimeout(contentHoveringTimer.current)
      }
      setContentHovering(true)
    }, [])

    const handleContentMouseLeave = useCallback(() => {
      if (contentHoveringTimer.current) {
        clearTimeout(contentHoveringTimer.current)
      }
      contentHoveringTimer.current = setTimeout(() => {
        setContentHovering(false)
      }, 200)
    }, [])

    const realOpen = open ?? (popOnHover ? triggerHovering || contentHovering : open)

    useEffect(() => {
      if (realOpen !== undefined) {
        onOpenChange?.(realOpen)
      }
    }, [realOpen, onOpenChange])

    const { isBelowMd } = useBreakpoint('md')

    return (
      <BasePopover open={realOpen} onOpenChange={onOpenChange} {...props}>
        <PopoverTrigger asChild onMouseEnter={handleTrigerMouseEnter} onMouseLeave={handleTriggerMouseLeave}>
          {trigger}
        </PopoverTrigger>
        <PopoverPortal>
          <PopoverContent
            collisionPadding={16}
            sideOffset={8}
            className={cls('p-4 w-[300px] backdrop-blur-xl rounded-lg', className)}
            align={align}
            side={side}
            data-component='creation-input'
            data-outside='false'
            updatePositionStrategy='optimized'
            onOpenAutoFocus={(e) => e?.preventDefault()}
            onMouseEnter={isBelowMd ? undefined : handleContentMouseEnter}
            onMouseLeave={isBelowMd ? undefined : handleContentMouseLeave}
            onScroll={(e) => {
              if (isBelowMd && e.target === e.currentTarget) {
                const div = e.target as HTMLDivElement
                if (div.scrollTop + div.clientHeight >= div.scrollHeight) {
                  // scroll up a bit to prevent iOS hanging
                  div.scrollTop = div.scrollHeight - div.clientHeight - 8
                }
              }
            }}
          >
            {children}
            <div className='h-2 md:hidden' />
            <PopoverClose asChild className='hidden'>
              <div ref={hiddenCloseHandleRef} className='hidden' />
            </PopoverClose>
          </PopoverContent>
        </PopoverPortal>
      </BasePopover>
    )
  },
)

Popover.displayName = 'Popover'
export default Popover
