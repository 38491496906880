import Button, { ButtonProps } from '@/components/button'
import { FREE_TIER_ID, HIGHEST_TIER_ID } from '@/constants'
import useActivePlan from '@/hooks/useActivePlan'
import useAmplitude from '@/hooks/useAmplitude'
import IconCredit from '@/public/assets/diamond.svg'
import { useRouter } from 'next/navigation'
import { useCallback, useMemo } from 'react'
import { cls, whisper } from '@/utils'
import { useSetAtom } from 'jotai'
import { getCreditDialogOpenAtom } from '@/atoms'
import useCredit from '@/hooks/useCredit'

const showDiscount = false

export interface UpgradeButtonProps extends ButtonProps {
  source: string
  showMembership?: boolean
}

export default function CreditUpgradeButton({
  className,
  onClick,
  source,
  showMembership = false,
  ...props
}: UpgradeButtonProps) {
  const router = useRouter()

  const { data: plan } = useActivePlan()
  const { creditBalance } = useCredit()
  const setGetCreditDialogOpen = useSetAtom(getCreditDialogOpenAtom)
  const { track } = useAmplitude()
  const { data: activePlan } = useActivePlan()

  const isFreePlan = Boolean(activePlan?.is_free || !activePlan)

  const shouldShowMembership = showMembership && plan?.tier_id && plan?.tier_id !== FREE_TIER_ID

  const buttonText = useMemo(() => {
    if (shouldShowMembership) {
      return plan?.name ?? 'Upgrade'
    }

    return 'Upgrade'
  }, [shouldShowMembership, plan])

  const handleUpgrade = useCallback(
    async (e: any) => {
      e?.preventDefault()
      e?.stopPropagation()

      const eventName = shouldShowMembership ? 'click:membership:membership-button' : 'click:membership:upgrade-button'

      track(eventName, { source })

      await onClick?.(e)
      router.push('/membership')
    },
    [onClick, router, track, source, shouldShowMembership],
  )

  if (plan?.tier_id === HIGHEST_TIER_ID && !showMembership) {
    return null
  }

  const openGetCredit = (e: any) => {
    if (isFreePlan) {
      handleUpgrade(e)
    } else {
      setGetCreditDialogOpen(true)
    }
  }

  return (
    <div
      className={cls(
        'relative flex border border-b-2 rounded-md bg-surface cursor-pointer border-border-button items-center h-10',
        isFreePlan ? 'hover:bg-surface-hover' : null,
      )}
    >
      <Button
        variant='transparent'
        className={cls('px-2 rounded-l-sm rounded-r-none', isFreePlan ? 'bg-transparent' : '')}
        onClick={openGetCredit}
      >
        <div className='flex items-center rounded-l-md'>
          <IconCredit className='mr-1' />
          <span className='px-1'>{creditBalance || 0}</span>
        </div>
      </Button>
      <div className='w-[1px] h-4 bg-border-button'></div>
      <Button
        {...props}
        variant='transparent'
        className={cls('rounded-r-sm rounded-l-none', className, isFreePlan ? 'bg-transparent' : '')}
        onClick={handleUpgrade}
      >
        <div className='flex items-center gap-1 px-1'>
          {/* <IconRocketLaunch className='size-5' /> */}
          {/* <IconBadgeVerified className='size-5' /> */}
          <span>{buttonText}</span>
        </div>
      </Button>
      {showDiscount && (
        <div className='absolute top-0 right-0 rounded-[5px] bg-surface-primary text-text-on-color text-body-sm font-bold leading-3 px-1 py-0.5 translate-x-2 -translate-y-2'>
          20% Off
        </div>
      )}
    </div>
  )
}
