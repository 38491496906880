import { CreationInputControlsProps } from '../common'
import { useMemo } from 'react'
import useActiveTemplate from '@/hooks/useActiveTemplate'
import { TemplateDetail, TemplateInputWidget } from '@/types'
import TemplateWidgets from './widgets'

export default function TemplateControls({
  expanded,
  templateInputs,
  setTemplateInputs,
}: Pick<CreationInputControlsProps, 'expanded' | 'templateInputs' | 'setTemplateInputs'>) {
  const { data: template } = useActiveTemplate()

  const inputs: TemplateInputWidget[] = useMemo(() => {
    const visibleInputs = ((template as TemplateDetail)?.inputs ?? []).filter((item) => item.visible !== false)

    const hasSam = visibleInputs.some((item) => item.widget === 'sam')

    // if the template has sam, only show sam
    const validTypes = hasSam
      ? ['sam']
      : ['image_input', 'video_input', 'sam', 'text_input', 'model', 'garment', 'clothes']

    const validInputs = (visibleInputs ?? [])?.filter((item) => validTypes.includes(item.widget))
    return validInputs
  }, [template])

  return (
    <TemplateWidgets
      className={expanded ? '' : 'hidden'}
      inputs={inputs}
      templateInputs={templateInputs}
      setTemplateInputs={setTemplateInputs}
    />
  )
}
