import { getStripePricingTable } from '@/service/pay.service'
import { useCachedService } from '@banyudu/use-service'
import useAuth0Auth from './useAuth0Auth'
import { useMemo } from 'react'

const skipableGetStripePricingTable = async (params?: { skip?: boolean }) => {
  if (params?.skip) return null
  const res = await getStripePricingTable()
  return res
}

// const _usePricingTable = useService(skipableGetStripePricingTable)
// const usePricingTable = () => {
//   const { isLogin } = useAuth0Auth()

//   const params = useMemo(() => {
//     if (!isLogin) return { skip: true }
//     return {}
//   }, [isLogin])

//   return _usePricingTable(params)
// }

const _useCachedPricingTable = useCachedService(skipableGetStripePricingTable)

export const useCachedPricingTable = () => {
  const { isLogin } = useAuth0Auth()

  const params = useMemo(() => {
    if (!isLogin) return { skip: true }
    return {}
  }, [isLogin])

  return _useCachedPricingTable(params)
}

// export default usePricingTable
