import { cls } from '@/utils'
import Checked from '@/public/assets/checkbox-checked.svg'
import UnCheck from '@/public/assets/checkbox-uncheck.svg'

export interface CheckboxProps {
  className?: string
  checked: boolean
  onChange?: (checked: boolean) => void
}

export default function Checkbox({
  className,
  checked,
  onChange,
}: CheckboxProps) {
  const Icon = checked ? Checked : UnCheck
  return (
    <div
      className={cls(
        'cursor-pointer flex items-center justify-center min-w-max min-h-max',
        className,
      )}
    >
      <Icon className={cls('w-4 h-4')} onClick={() => onChange?.(!checked)} />
    </div>
  )
}
