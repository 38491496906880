import Tabs from '@/components/tabs'
import { useCallback } from 'react'

export interface BooleanInputProps {
  className?: string
  value: boolean | null
  onChange: (value: boolean | null) => void
}

const naValue = 'n/a'

const tabsOptions = [
  { label: 'True', value: 'true' },
  { label: 'N/A', value: naValue },
  { label: 'False', value: 'false' },
]

export default function BooleanInput({ className, value, onChange }: BooleanInputProps) {
  const handleChange = useCallback(
    (value: string) => {
      if (value === naValue) {
        onChange(null)
      } else {
        onChange(value === 'true')
      }
    },
    [onChange],
  )

  const realValue = value === null ? naValue : value ? 'true' : 'false'

  return (
    <Tabs
      className={className}
      itemClassName='text-body-md font-normal'
      items={tabsOptions}
      value={realValue}
      onChange={handleChange}
    />
  )
}
