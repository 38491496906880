import { getTemplates } from '@/service/template.service'
import { PaginationQuery } from '@/types'
import useAuthedSkip from './useAuthedSkip'
import useService, { useCachedService } from '@banyudu/use-service'

const getHotTemplates = async (params: PaginationQuery<{}>) => {
  const res = await getTemplates({
    ...params,
    tag: 'hot',
  })
  return res
}

const useHotTemplates = useService(getHotTemplates, useAuthedSkip())

export const useCachedHotTemplates = useCachedService(getHotTemplates, useAuthedSkip())

export default useHotTemplates
