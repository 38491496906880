import Button, { ButtonProps } from '@/components/button'
import { FREE_TIER_ID } from '@/constants'
import useActivePlan from '@/hooks/useActivePlan'
import useAmplitude from '@/hooks/useAmplitude'
import IconBadgeVerified from '@haiper/icons-svg/icons/outline/badge-verified.svg'
import { useRouter } from 'next/navigation'
import { useCallback, useMemo } from 'react'
import { cls } from '@/utils'

export interface UpgradeButtonProps extends ButtonProps {
  source: string
  showMembership?: boolean
}

export default function UpgradeButton({
  className,
  onClick,
  source,
  showMembership = false,
  ...props
}: UpgradeButtonProps) {
  const router = useRouter()

  const { data: plan } = useActivePlan()
  const { track } = useAmplitude()

  const shouldShowMembership = showMembership && plan?.tier_id && plan?.tier_id !== FREE_TIER_ID

  const buttonText = useMemo(() => {
    if (shouldShowMembership) {
      return plan?.name ?? 'Upgrade'
    }

    return 'Upgrade'
  }, [shouldShowMembership, plan])

  const handleUpgrade = useCallback(
    async (e: any) => {
      e?.preventDefault()
      e?.stopPropagation()

      const eventName = shouldShowMembership ? 'click:membership:membership-button' : 'click:membership:upgrade-button'

      track(eventName, { source })

      await onClick?.(e)
      router.push('/membership')
    },
    [onClick, router, track, source, shouldShowMembership],
  )

  return (
    <Button {...props} variant='primary' className={cls('rounded-md', className)} onClick={handleUpgrade}>
      <div className='flex items-center gap-1'>
        {/* <IconRocketLaunch className='size-5' /> */}
        <IconBadgeVerified className='size-5' />
        <span className='px-0.5 md:px-1'>{buttonText}</span>
      </div>
    </Button>
  )
}
