import Button from '@/components/button'
import useActiveTemplate from '@/hooks/useActiveTemplate'
import { TemplateDetail } from '@/types'
import { cls } from '@/utils'
import IconQuestionMark from '@haiper/icons-svg/icons/solid/questionmark.svg'
import { useSetAtom } from 'jotai'
import { showCreationModeTutorialAtom } from '@/atoms'
import { useCallback } from 'react'

export interface CreationModeTutorialButtonProps {
  className?: string
}

export default function CreationModeTutorialButton({ className }: CreationModeTutorialButtonProps) {
  const { data: template } = useActiveTemplate()
  const tutorial = (template as TemplateDetail)?.tutorial
  const showTutorial = tutorial && tutorial.length > 0

  const setShowCreationModeTutorial = useSetAtom(showCreationModeTutorialAtom)

  const handleOpenTutorial = useCallback(() => {
    setShowCreationModeTutorial(true)
  }, [setShowCreationModeTutorial])

  if (!showTutorial) return null

  return (
    <Button
      variant='outline'
      className={cls('size-10 rounded-full border border-b-2 p-0', className)}
      onClick={handleOpenTutorial}
    >
      <IconQuestionMark className='size-6 text-text-interactive' />
    </Button>
  )
}
