import { useAtom } from 'jotai'
import { FC, forwardRef, useMemo } from 'react'
import Button, { ButtonProps } from '@/components/button'
import { cls } from '@/utils'
import { creationInputAtom } from '@/atoms'
import ChevronDown from '@haiper/icons-svg/icons/outline/chevron-down-small.svg'
import useCreationModes from '@/hooks/useCreationModes'
import { useCachedTemplateCategories } from '@/hooks/useTemplateCategories'
import TemplateIcon from '../../templates/icon'
import useActiveTemplate from '@/hooks/useActiveTemplate'
import { TemplateCategory } from '@/types'
// import { Badge } from '@/components/ui/badge'

export interface ButtonCreationModeProps extends ButtonProps {
  className?: string
  iconClassName?: string
  Icon?: FC<{ className?: string }>
}

export const ButtonCreationMode = forwardRef<HTMLButtonElement, ButtonCreationModeProps>(
  ({ className, iconClassName, Icon, ...props }, ref) => {
    const [{ mode }] = useAtom(creationInputAtom)
    const { data: creationModeOptions } = useCreationModes()
    const selectedCreationMode = creationModeOptions.find((option) => option.mode === mode) || creationModeOptions[0]

    const { data: templateCategories } = useCachedTemplateCategories()

    const { data: activeTemplate, id: activeTemplateId } = useActiveTemplate()

    const { Icon: ModeIcon, iconClassName: modeIconClassName } = selectedCreationMode ?? {}

    const activeTemplateCategory = useMemo(
      () =>
        templateCategories?.find(
          (category: TemplateCategory) => Boolean(category.category) && category.category === activeTemplate?.category,
        ),
      [templateCategories, activeTemplate],
    )

    const categoryIcon = useMemo(() => {
      if (activeTemplateId) {
        const Icon = ({ className }: any) => (
          <TemplateIcon src={activeTemplateCategory?.icon} className={cls('bg-surface', className)} />
        )
        return Icon
      }
      return null
    }, [activeTemplateId, activeTemplateCategory])

    const RealIcon = categoryIcon ?? Icon ?? ModeIcon ?? null

    const modeName = activeTemplateId ? activeTemplate?.name : selectedCreationMode?.name

    return (
      <Button
        ref={ref}
        aria-label={modeName}
        type='button'
        variant='outline'
        tooltip='Creation Mode'
        tooltipProps={{
          side: 'bottom',
        }}
        className={cls(
          'flex items-center justify-center h-10 p-2',
          'flex justify-center gap-3 text-body-md cursor-pointer select-none text-text h-12 rounded-[48px] border-2 border-solid border-border transition-all duration-200 ease-in-out hover:border-border-hover active:border-border-hover bg-surface hover:bg-surface-hover active:bg-surface-hover hover:shadow-[0_0_10px_0_rgba(250,250,250,0.2)] active:shadow-[0_0_10px_0_rgba(250,250,250,0.2)]',
          'items-center relative',
          className,
        )}
        {...props}
        data-testid='creation-mode-button'
      >
        <div className='flex items-center gap-2'>
          {RealIcon ? (
            <div
              className={cls(
                'size-10 p-0 rounded-full shrink-0 transition-all duration-200 ease-in-out opacity-100 text-icon-on-color relative flex items-center justify-center',
                'size-6 p-1',
                !activeTemplate && modeIconClassName,
                !activeTemplate && iconClassName,
              )}
            >
              <RealIcon className={cls('size-4', !!activeTemplate && 'size-6 p-1')} />
            </div>
          ) : null}
          <span
            aria-label='modeName'
            // className='text-heading-lg font-bold hidden md:block'
            className='text-body-md hidden md:block'
          >
            {modeName}
          </span>
          <ChevronDown className='size-6 text-icon' />
        </div>
      </Button>
    )
  },
)

ButtonCreationMode.displayName = 'ButtonCreationMode'
